.meta-blocks {
    &--title {
        font-size: 30px;
    }

    &--container {
        border-color: #E6E6E6;
        border-left: 0;
        border-right: 0;
        border-style: solid;
        margin: 2rem 0;
    }

    &--content {
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        padding: 2rem 0 0;

        a {
            font-size: 16px;
            text-decoration: underline;
        }
    }

    &--content-heading {
        color: $body-color;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &--col {
        margin-bottom: 1.5rem;

        @media #{$large-and-up} {
            margin-bottom: 2rem;
        }

        :not(:first-child) {
            margin: 0;
        }
    }
}
