.tooltip {
    .tooltip-inner  {
        background-color: $body-color;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: to-rem(18);
    }

    &.bs-tooltip-top .arrow::before {
        border-top-color: $body-color;
    }

    &.bs-tooltip-left .arrow::before {
        border-left-color: $body-color;
    }

    &.bs-tooltip-right .arrow::before {
        border-right-color: $body-color;
    }

    &.bs-tooltip-bottom .arrow::before {
        border-bottom-color: $body-color;
    }
}

.tippy-box {
    background-color: $body-color;

    .tippy-content{
        font-family: 'Source Sans Pro', sans-serif;
        font-size: to-rem(18);
    }
}
