.horizontal-collapse {
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
    text-align: right;

    .btn-floating {
        span {
            color: color('white', 'base');
            display: inline-block;
            text-align: center;
            width: inherit;
        }

        &.btn-large span {
            font-size: 1.75rem;
            line-height: 2;
        }
    }

    .btn-floating-collapse {
        @extend .z-depth-2;
        margin-right: 25px;
    }

    .expand-horizontal {
        @extend .z-depth-1;
        background-color: color('nijmegen-primary', 'base');
        color: color('white', 'base');
        margin: 0;
        padding-left: 10px;
        position: absolute;
        right: 0;
        top: 14px;
        transform: translateX(150%);
        padding-right: 16px;

        @media #{$medium-and-up} {
            padding-right: 20px;
        }

        li {
            display: inline-block;
            line-height: 44px;

            .mdi {
                color: color('white', 'base');
                line-height: inherit;
            }

            button, a
            {
                padding: 0 6px;

                @media #{$medium-and-up} {
                    padding: 0 10px;
                }
            }

            button {
                background: none;
                border: 0;
                margin: 0;
            }

            a {
                display: block;
            }

            a:hover,
            a:focus,
            button:hover,
            button:focus {
                background: color('nijmegen-primary', 'lighten-2');
            }

            a:focus,
            button:focus {
                @include tab-focus;
            }

            &.btn-floating-wrapper {
                width: 70px;
            }
        }
    }

    &.active {
        .btn-floating-collapse {
            background-color: color('nijmegen-primary', 'darken-3');

            &:hover, &:focus{
                background-color: color('nijmegen-primary', 'darken-2');
            }
        }

        .expand-horizontal {
            transform: translateX(0%);
        }
    }
}
